import React, { useCallback, useMemo } from "react";
import { navigate } from "gatsby";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { BLOG_PAGE_PATH, TABLET_MAX_WIDTH } from "@constants";
import Button from "../Common/Button/Button";
import "./ThankYou.scss";
import CircleBlur from "../Common/CircleBlur/CircleBlur";

const ThankYou = ({ isCareers }) => {
    const handleClick = useCallback(() => {
        navigate(BLOG_PAGE_PATH);
    }, [navigate]);

    const { width } = useWindowDimensions();
    const isDesktop = useMemo(
        () => width > TABLET_MAX_WIDTH,
        [width, TABLET_MAX_WIDTH]
    );

    const title = useMemo(() => {
        if (isCareers) {
            return (
                <>
                    Wow! Impressive CV!
                    <br />
                    Thank you for your submission.
                </>
            );
        }

        return (
            <>
                Thank you for {!isDesktop && <br />}
                submitting your {isDesktop && <br />}
                request{!isDesktop && <br />} to Impressit
            </>
        );
    }, [isDesktop]);

    const description = useMemo(() => {
        if (isCareers) {
            return (
                <>
                    We will get back to you ASAP.
                    <br />
                    You can read our blog while waiting for our response.
                </>
            );
        }

        return (
            <>
                We will get back to you within an hour or so.
                {!isDesktop && <br />} Unless it&apos;s weekend ;)
                <br />
                Meanwhile, feel free to check our blog.
            </>
        );
    }, [isDesktop]);

    return (
        <div className="thank-you-section">
            <CircleBlur
                width="282px"
                height="282px"
                top="96px"
                blur="blur(400px)"
                left="0"
                color="var(--light-purple)"
            />
            <h1 className="thank-you-section__heading">{title}</h1>
            <div className="thank-you-section__content">
                <p className="thank-you-section__description">{description}</p>
                <div className="thank-you-section__btn-container">
                    <Button text="Read the blog" handleClick={handleClick} />
                </div>
            </div>
        </div>
    );
};

export default ThankYou;
